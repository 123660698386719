
@media (min-width: 1024px){
    .section_1_background {
        padding-left: 8.875rem;
        padding-right: 8.875rem;
    }
    .section_1_header {
        margin-top: 1.5rem !important;
        margin-bottom: 1rem !important;
    }
    .section_2 {
        padding-top: 2rem;
    }
    .section_6_text {
        width:80%
    }
    .section_6_quote_start {
        margin-left: -1.875rem !important;
    }
    .section_6_quote_end {
        margin-right: -1.875rem !important;
    }
    .section_3_info {
        margin-right: 0.5rem;
    }
    .section_3_button {
        width: max-content;
    }
    .home_padding_gutter {
        padding-left: 8.875rem;
        padding-right: 8.875rem;
    }
}

@media(min-width: 1440px) {
    .section_7_header {
        max-width: 75%;
    }
}

@media (max-width: 900px) {
    .section_2_mobile {
        margin-top: 2rem !important;
    }
}

@media (min-width: 900px) {
    .section_2_mobile {
        margin-top: 0rem !important;
    }
}

@media (max-width: 400px) {
    .home_dialog_expand_screenspace {
        width: 100% !important;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
    }
}

@media (max-width: 1024px) {
    .section_3_info {
        margin-right: auto;
    }
    .section_3_highlight {
        margin-right: auto !important;
    }
    .gutter {
        padding-left: 5%;
        padding-right: 5%;
    }
    .section_1_header {
        margin-top: 0rem !important;
        margin-bottom: 1.5rem !important;
    }
    .section_1_background {
        padding-left: 5%;
        padding-right: 5%;
    }
    .section_2_box {
        padding-left: 2.5%;
        padding-right: 2.5%;
    }
    .section_2_end {
        padding-top: 2rem !important;
    }
    .section_6_image {
        margin-bottom: 2.25rem;
    }
    .section_6_text {
        width:62%
    }
    .section_7_image_container {
        margin-top: 3rem !important;
    }
    .section_2_text_center {
        padding-top: 2.25rem;
    }
    .section_7_button {
        width: 100%;
    }
    .section_8_button {
        width: 100% !important;
    }
}

.gutter {
    max-width: 86.875rem;
}

.home_background {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section_1_background {
    background-color: var(--dss-light-teal);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 3rem;
}

.section_1 {
    padding-top: 1.5rem;
    padding-bottom: 2.25rem;
    max-width: 86.875rem;
}

.section_1_header {
    text-align: left !important;
    font: normal normal bold  1.75rem/2.5rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: var(--primary-dark-gray) !important;
}

.section_1_text {
    text-align: left !important;
    font: normal normal 500 1.1875rem/1.625rem Open Sans !important;
    letter-spacing: 0rem !important;
    white-space: pre-wrap;
    margin-bottom: 0.625rem !important;
}

.section_1_image_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    place-items: center;
}

.section_1_image {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 27rem;
}

.section_1_highlight {
    text-align: left !important;
    text-decoration: underline;
    font: normal normal 600 1.1875rem/1.625rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: var(--primary-purple) !important;
    cursor: pointer;
}

.section_2 {
    padding-top: 2rem;
    max-width: 1300px;
}

.section_2_container {
    display: flex;
    flex: 1;
    flex-direction: column !important;
}

.section_2_text {
    font: normal normal 600 1.125rem/1.5625rem Open Sans !important;
    letter-spacing: 0.0063rem !important;
    color: var(--primary-black) !important;
    margin-bottom: 2rem !important;
}

.section_2_text_center {
    text-align: center;
    font: normal normal bold 1.125rem/1.5625rem Open Sans !important;
    letter-spacing: 0.0063rem;
    color: var(--primary-black) !important;
}

.section_2_image_container {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.125rem 3rem #faf7fc;
    border: 0.0625rem solid #D8D8D8;
    border-radius: 0.9375rem;
    height: 75%;
    padding: 0.5rem;
    display: block;
    max-height: 540px;
}

.section_2_button {
    box-shadow: 0rem 0.1875rem 0.375rem #60617029;
    width: 100%  !important;
    margin-top: auto !important;
    display: flex;
    flex-direction: column;
}

.section_2_button_text {
    text-align: center;
    font: normal normal 600 0.875rem/1.5625rem Open Sans !important;
    letter-spacing: 0.0437rem;
    margin: 0rem !important;
}

.section_2_button_text_preview {
    text-align: center;
    font: normal normal 600 0.75rem/1.5625rem Open Sans !important;
    letter-spacing: 0.0437rem;
    margin: 0rem !important;
}

.section_2_allow_click {
    pointer-events: none;
}

.section_2_image {
    max-width: 100%;
    max-height: 100%;
    padding: 0.5rem;
    pointer-events: all;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.section_2_end {
    padding-top: 4rem;
    padding-bottom: 2rem;
    max-width: 325px;
}

.section_3_background {
    background: #ece7f1 0% 0% no-repeat padding-box;
}

.section_button {
    box-shadow: 0rem 0.1875rem 0.375rem #60617029;
    width: fit-content  !important;
    margin-top: auto !important;
}

.section_button_text {
    text-align: center;
    font: normal normal 600 0.875rem/1.5625rem Open Sans !important;
    letter-spacing: 0.0437rem;
    margin: 0rem !important;
    padding: 0 1rem;
}

.section_3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.section_3B {
    padding-top: 4rem;
    padding-bottom: 3rem;
}

.section_3_header {
    text-align: left !important;
    font: normal normal bold  1.75rem/2.5rem Open Sans !important;
    letter-spacing: 0rem;
    color: var(--primary-dark-gray) !important;
    padding-bottom: 0.5rem;
}

.section_3_text {
    text-align: left !important;
    font: normal normal normal 1.125rem/1.625rem Open Sans !important;
    letter-spacing: 0.0144rem;
    color: var(--primary-dark-gray) !important;
    white-space: pre-wrap;
    margin-top: 0.5rem !important;
}

.section_3_point_text {
    text-align: left !important;
    font: normal normal normal 1.125rem/1.625rem Open Sans !important;
    letter-spacing: 0.0144rem;
    color: var(--primary-black);
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
}

.section_3_point {
    text-align: left !important;
    font: normal normal normal 1.125rem/1.625rem Open Sans !important;
    letter-spacing: 0.0144rem;
    color: var(--primary-black);
    white-space: pre-wrap;
    width: fit-content;
    height: 100%;
}

.section_3_button {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.section_3_info {
    border-radius: 100%;
    color: var(--primary-purple-text) !important;
    height: 28px !important;
    width: 28px !important;
}

.section_3_highlight {
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 1.125rem/1.625rem Open Sans !important;
    letter-spacing: 0.0181rem !important;
    color: var(--primary-purple-text) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section_4 {
    padding-top: 2rem;
    padding-bottom: 3rem;
    
}

.section_4_top_header {
    font: normal normal bold  1.75rem/2.5rem Open Sans !important;
    letter-spacing: 0rem;
    color: var(--primary-dark-gray) !important;
}

.section_4_top_text {
    font: normal normal normal 1.125rem/1.625rem Open Sans !important;
    letter-spacing: 0.0144rem;
    color: var(--primary-dark-gray) !important;
    white-space: pre-wrap;
    margin-top: 1.5rem !important;
}

.section_4_image_container {
    background-color: #004052;
    height: 196px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.section_4_image {
    height: fit-content;
    align-self: center;
    max-width: 100%;
    max-height: 100%;
}

.section_4_image_scaledown {
    height: fit-content;
    align-self: center;
    max-width: 75%;
    max-height: 75%;

}

.section_4_left_text {
    text-align: left !important;
}

.section_4_grid {
    max-width: 1050px;
    height: 100%;
}

.section_4_container {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.125rem 3rem #FAF7FC;
    border: 0.0625rem solid #D8D8D8;
    border-radius: 0.9375rem;
    height: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
}

.section_4_header {
    display: flex;
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
    text-align: left !important;
    font: normal normal bold 1rem/1.5rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: var(--primary-dark-gray) !important;
}

.section_4_header_span {
    margin-top: 0.5rem;
    margin-left: 0.3125rem;
}

.section_4_info {
    background: var(--primary-purple-text) 0% 0% no-repeat padding-box;
    border-radius: 100%;
    color: white !important;
    margin: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: fit-content;
}

.section_4_underline {
    text-decoration: underline;
}

.section_5_background {
    background: #5B3084 0% 0% no-repeat padding-box;
}

.section_5_header {
    text-align: center;
    font: normal normal bold 1.75rem/2.5rem Open Sans !important;
    letter-spacing: 0rem;
    color: var(--primary-white) !important;
}

.section_5_container {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem var(--primary-black);
    border: 0.0625rem solid #BEBEBE;
    border-radius: 0.9375rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.section_container_splitter {
    width: 20px;
}

.section_5_icon {
    height: 52px;
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.section_5_container_text {
    text-align: center;
    font: normal normal bold  1.125rem/1.625rem Open Sans !important;
    letter-spacing: 0rem;
    color: var(--primary-dark-gray) !important;
    margin: 1.5rem !important;
    margin-bottom: 2rem !important;
    white-space: pre-wrap;
}

.section_5_button {
    width: fit-content;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.section_5_button_text {
    text-align: center;
    font: normal normal 600 0.875rem/1.5625rem Open Sans !important;
    letter-spacing: 0.0437rem;
    margin: 0rem !important;
    padding: 0 1rem;
}

.section_6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.section_6_container {
    display: flex;
    flex-direction: column !important;
}

.section_6_flex_center {
    display: flex;
    justify-content: center;
}

.section_6_button_container {
    margin-top: auto;
}

.section_6_image_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    place-items: center;
}

.section_6_button {
    color: var(--primary-purple) !important;
}

.section_6_header {
    text-align: left !important;
    color: var(--primary-dark-gray) !important;
    font: normal normal 800 1.375rem/2.1875rem Open Sans !important;
    letter-spacing: 0.1525rem !important;
    margin-top: 2rem !important;
}

.section_6_text_container {
    display: flex;
    justify-content: center;
}

.section_6_text {
    text-align: left !important;
    font: normal normal normal 1.1875rem/2rem Open Sans !important;
    letter-spacing: 0rem;
    color: #202529 !important;
}

.section_6_quote_container {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
}
.section_6_quotemark {
    font: normal normal bold 7.5rem/1.875rem Arial !important;
    letter-spacing: 0rem !important;
    color: #DEDEDE !important;
    opacity: 0.4;
}
.section_6_quote_start {
    text-align: left !important;
    line-height: 100% !important;
    position: absolute;
    margin-top: -3.75rem !important;
}
.section_6_quote_end {
    text-align: right !important;
    line-height: 100% !important;
    margin-top: -5rem !important;
}

.section_6_image {
    max-width: 100%;
}

.section_7_background {
    background: url("../../Assets/backgrounds/HomePage_PurpleBanner_1500x.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
}

.section_7_container {
    align-self: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.section_7_header {
    text-align: left !important;
    font: normal normal bold 1.75rem/2.5rem Open Sans !important;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    letter-spacing: 0rem;
    color: var(--primary-yellow) !important;
}

.section_7_text_container {
    margin-top: 1.5rem !important;
    margin-bottom: 2.5rem !important;
}

.section_7_text_section {
    display: flex;
}

.section_7_point {
    min-width: 2rem;
}

.section_7_text {
    text-align: left !important;
    font: normal normal normal 1rem/1.875rem Open Sans !important;
    color: var(--primary-white) !important;
    white-space: pre-wrap;
    width: fit-content;
}

.section_7_button {
    border-radius: 1.5625rem !important;
}

.section_7_button_text {
    text-align: center;
    font: normal normal 600 0.875rem/1.5625rem Open Sans !important;
    letter-spacing: 0.0437rem !important;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin: 0px !important
}

.section_7_image {
    max-width: 100%;
}

.section_8 {
    padding-top: 3.25rem;
    padding-bottom: 4rem;
}

.section_8_container {
    display: flex;
    flex-direction: column !important;
}

.section_8_header {
    font: normal normal bold  1.75rem/2rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: #070707 !important;
}

.section_8_text {
    text-align: center;
    font: normal normal normal 1.125rem/1.625rem Open Sans !important;
    letter-spacing: 0rem;
    color: #070707 !important;
    text-shadow: 0rem 0.1875rem 0.375rem #3E3E3E29;
    padding-top: 1.125rem;
}

.section_8_button {
    width: 70%;
    margin-top: 2.25rem !important;
    align-self: center;
}

.section_7_image_container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    place-items: center;
}
