
@media (min-width: 1023px){
    .header_gutter {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }
    .headerButtonText {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .headerMobileMenuButton {
        display: none !important;
    }
    .headerMobileDrawer {
        display: none !important;
    }
    .headerTitle {
        font: normal normal bold 1.375rem/2rem Open Sans !important;
    }
}

@media (min-width: 1440px){
    .header_gutter {
        padding-left: 8.875rem !important;
        padding-right: 8.875rem !important;
    }
    .headerButtonText {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

@media (max-width: 1023px){
    .headerOption {
        display: none;
    }
    .headerButton {
        display: none !important;
    }
    .headerOptionDropdown {
        display: none !important;
    }
    .headerTitle {
        font: normal normal bold 1rem/1.25rem Open Sans !important;
        white-space: pre-wrap;
    }

    .headerMobileDrawer_TextSubContainer {
        padding: 1rem 1.875rem 1rem 4.3rem;
    }
    .headerMobileDrawer_TextContainer {
        padding: 1rem 1.875rem 1rem 4.3rem;
    }
    .headerMobileDrawer_AccordionSummary {
        padding: 1rem 1.875rem 1rem 1.875rem !important;
    }
}

.headerPadding {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0.1875rem 0.375rem #60617029;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.headerTitle {
    text-align: left !important;
    letter-spacing: 0.04875rem !important;
    color: var(--primary-purple) !important;
    font-weight: bold  !important;
    opacity: 1;
    cursor: pointer;
    padding-right: 0.625rem;
    padding-bottom: 0.1875rem;
    flex-shrink: 3;
}

.headerOptionSelected {
    color: var(--primary-purple) !important;
    font: normal normal bold 1rem/2rem Open Sans !important;
}

.headerOptionRegular {
    color: #101010 !important;
    font: normal normal 600 1rem/2rem Open Sans !important;
}

.headerOption {
    text-align: center;
    letter-spacing: 0.03rem !important;
    opacity: 1;
    cursor: pointer;
    margin-right: 0.625rem !important;
    padding-bottom: 0.1875rem;
    flex-shrink: 2;
}

.headerOption:hover {
    border-bottom: 0.1875rem solid var(--primary-purple);
    padding-bottom: 0rem;
}

.headerOptionDropdown {
    text-align: center;
    letter-spacing: 0.03rem !important;
    opacity: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 0.625rem !important;
    padding-bottom: 0.1875rem;
    flex-shrink: 2;
}

.headerOptionDropdown:hover {
    border-bottom: 0.1875rem solid var(--primary-purple);
    padding-bottom: 0rem;
}

.headerUpIcon {
    transform: rotate(-180deg);
    color: var(--primary-purple);
    opacity: 1;
    margin-left: 0.3125rem;
}

.headerDownIcon {
    transform: rotate(0);
    color: var(--primary-purple);
    opacity: 1;
    margin-left: 0.3125rem;
}

.headerCancelIcon {
    color: var(--primary-purple);
    opacity: 1;
}

.headerButton {
    border-radius: 2rem !important;
    opacity: 1 !important;
    min-width: unset !important;
    display: flex;
    flex-direction: column;
}

.headerButtonText {
    text-align: center;
    font: normal normal 600 1rem/1.25rem Open Sans !important;
    letter-spacing: 0rem !important;
    opacity: 1;
    margin: 0rem !important;
}

.headerButtonText_Preview {
    text-align: center;
    font: normal normal 600 0.75rem/1.25rem Open Sans !important;
    letter-spacing: 0rem !important;
    opacity: 1;
    margin: 0rem !important;
    white-space: pre-wrap;
}

.headerMobileDrawer {
    z-index: 10;
}

.headerMobileDrawerPadding {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.headerMobileDrawer_TextContainer {
    border-bottom: 0.0625rem solid #E8E8E8;
    opacity: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.headerMobileDrawer_Chevron {
    color: gray !important;
}

.headerMobileDrawer_TextMainTitle {
    text-align: left !important;
    font: normal normal normal 1rem/1.375rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: var(--primary-black) !important;
    opacity: 1;
    margin-left: 1em !important;
}

.headerMobileDrawer_TextTitle {
    text-align: left !important;
    font: normal normal normal 1rem/1.375rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: var(--primary-black) !important;
    opacity: 1;
}

.headerMobileDrawer_TextSubTitle {
    text-align: left !important;
    font: normal normal normal 1rem/1.375rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: #5B3084 !important;
    opacity: 1;
}

.headerMobileDrawer_TextSubContainer {
    background-color: #FAF7FC;
    border-bottom: 0.0625rem solid #E8E8E8;
    opacity: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.headerMobileDrawer_TextSubtitle {
    text-align: left !important;
    font: normal normal 600 0.625rem/0.75rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: #004052 !important;
    text-transform: uppercase;
    opacity: 0.5;
}

.headerMobileDrawer_Accordion {
    margin: 0 !important;
    box-shadow: none !important;
}

.headerMobileDrawer_Accordion::before {
    display: none;
}

.headerMobileDrawer_AccordionSummary {
    border-bottom: 0.0625rem solid #E8E8E8 !important;
}

.headerMobileDrawer_AccordionSummaryContent {
    margin: 0 !important;
}

.headerMobileDrawer_AccordionDetails {
    padding: 0rem !important;
}

.headerMobileDrawer_Button {
    margin: 5% !important;
    display: flex;
    flex-direction: column;
}

.headerMobileDrawer_ButtonText {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin: 0rem !important;
}