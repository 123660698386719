::-webkit-scrollbar {
    width:10px;
}

::-webkit-scrollbar-track {
    background-color: #F8F8F8;
}

::-webkit-scrollbar-thumb {
    background: #00000073;
    border-radius: 0.625rem;
    border: 0.1563rem solid transparent;
    background-clip: content-box;
}

.noWrap {
    white-space: nowrap;
}

.wrapAnywhere{
    overflow-wrap: anywhere;
}