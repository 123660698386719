@media (min-width:1023px) {
    .intro_background {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

@media (max-width: 1023px) {
    .intro_background {
        padding-top: 2.5rem;
        padding-bottom: 3.75rem;
    }
}

.intro_background {
    display:flex;
    background: url("../../../../Assets/backgrounds/HomePage_TealBanner_1500x.png");
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    padding-left:5%;
    padding-right:5%;
}

.intro_headingContainer {
    text-align: left !important;
}

.factsheet_container {
    display: flex;
    flex-wrap: wrap;
}

.factsheet_container_button {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-right: 1rem;
}

.factsheet_button {
    margin: 0px !important;
}

.factsheet_container_text {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-top: 1rem;
    overflow-wrap: anywhere;
}