@media (min-width: 63.9375rem) {
    .facts_background {
        padding-top: 2.8125rem;
        padding-bottom: 3.4375rem;
    }

    .facts_contentContainer {
        max-width: 80.625rem;
    }
}

@media (max-width: 63.9375rem) {
    .facts_background {
        padding-top: 2.5rem;
        padding-bottom: 3.75rem;
    }
}

.facts_background {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.facts_contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    box-sizing: border-box;
    width: 100%;
}

.facts_heading_start {
    display: block;
    height: 100%;
    width: 100%;
    text-align: left !important;
}

.facts_heading {
    display: inline;
    height: 100%;
    width: 100%;
}

.facts_intro_body {
    white-space: pre-line;
    margin-bottom: 1.1875rem !important;
    height: 100%;
}

.facts_update_date_error {
    font-size: 1rem !important;   
}

.fact_li_scale {
    width: 100%;
}

.facts_selectable {
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary-purple) !important;
}

.facts_selectable:hover {
    text-shadow:0rem 0rem 0.0313rem var(--primary-purple);
}

.black {
    color: var(--primary-black) !important;
}

.purple {
    color: var(--primary-purple) !important;
}

.yellow {
    color: var(--primary-yellow) !important;
}

.white {
    color: var(--primary-white) !important;
}

.error-red {
    color: var(--error-red) !important;
}

.whiteBackground {
    background-color: var(--primary-white);
}

.violetBackground {
    background-color: var(--dss-light-purple);
}

.purpleBackground {
    background-color: var(--primary-purple);
}