@media (min-width: 1023px){
    .signUp_background {
        padding-bottom: 8.5938rem;
        padding-top: 8.5rem;
        flex: 1 1;
    }

    .signUp_dialogueBox {
        padding-left: 2.1875rem;
        padding-right: 2.1875rem;
        padding-bottom: 1.875rem;
        padding-top: 1.25rem;
        max-width: 800px;
        height: fit-content;
    }

    .largeRadioButton {
        width: 385px;
    }

    .largeRadioButton_disabled {
        width: 385px;
    }
}

.largeRadioButton {
    background-color: var(--primary-white);
    border: 0.0625rem solid var(--primary-purple);
    border-radius: 1.5625rem;
    box-shadow: 0rem 0.5rem 0.75rem #60617029;
    text-align: center;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 2.0625rem;
    padding-top:1.5rem;
    margin-right: 0.4688rem;
    margin-left:0.4688rem;
    margin-top:0rem;
    margin-bottom:1.25rem;
    min-height:246px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: 94%;
    justify-content: space-between;
}

.radioText {
    margin-top: 1.25rem !important;
    margin-bottom: 0.9375rem !important;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 600 1.125rem/1.625rem Open Sans !important;
    letter-spacing: -0.0281rem !important;
    text-align: center !important;
}

.largeRadioButton_disabled {
    background-color: var(--primary-white);
    border: 0.0313rem solid #0000001f;
    border-radius: 1.5625rem;
    box-shadow: 0rem 0.5rem 0.75rem #60617029;
    text-align: center;
}

.largeRadioButton_disabled > .radioText {
    color: #484848;
}
