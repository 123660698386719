@media (min-width: 1023px) {
    .background {
        padding-left: 3.125rem;
        padding-right: 3.125rem;
    }
}

@media (max-width: 1034px) {
    .background {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 1.875rem;
        padding-bottom: 3.125rem;
    }

    .dialogueBox {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
    }

    .formBox {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
    }
    
    .pdfBox {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
        max-width: 100%;
    }
}

.background {
    display: flex;
    background: url("../Assets/backgrounds/Teal-banner-darker.png");
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
}

.backArrow {
    margin-right: 0.75rem;
}

.dialogueBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-white) !important;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
}

.formBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-white) !important;
    border: 0.0625rem solid #B5B5B5;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
}

.pdfBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.625rem 2.1875rem #00000029;
    border: 0.0625rem solid #B5B5B5;
    border-radius: 0.5rem;
    opacity: 1;
}

.textField {
    width: 100%;
}

.nextButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.widest {
    width: 100% !important;
}

.mpa_selectable {
    cursor: pointer;
}
