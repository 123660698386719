@media (min-width: 899px){
    .plb_quoteStart {
        margin-left: -1.875rem !important;
    }
    .plb_quoteEnd {
        margin-right: -1.875rem !important;
    }
    .plb_diagramSideGrid_mobile {
        display: none;
    }
    .plb_quoteImage {
        max-width: 450px;
    }
}

@media (max-width: 899px){
    .plb_diagramSideGrid {
        display: none !important;
    }
    .plb_quoteImage {
        max-width: 400px;
    }

}

@media (max-width: 499px) {
    .plb_quoteImage {
        max-width: 320px;
    }
}

.plb_diagramSideContainerColumn_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    justify-content: flex-end;
}

.plb_diagramSideContainerRow {
    display: flex;
    height: 100%;
    align-items: center;
}

.plb_diagramSideItemContainer {
    display:flex;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.plb_diagramBox {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border: 0.0625rem solid #BEBEBE;
    border-radius: 0.9375rem;
    padding:1.5625rem;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.plb_diagramBox_desktop {
    flex-direction: row;
    width: 80%;
}

.plb_diagramBox_mobile {
    flex-direction: column;
    height: 200px;
    max-width: 300px;
    padding: 0.9375rem;
}

.plb_diagramBody {
    font-size: 1.375rem !important;
    text-align: left !important;
    margin: 0.625rem !important;
}

.plb_diagramBody_mobile {
    font-size: 0.9375rem !important;
    text-align: center !important;
    line-height: 1.25rem !important;
    margin: 0.4375rem !important;
}

.plb_diagramIcon {
    max-height: 100px;
    max-width: 80px;
}

.plb_diagramIcon_mobile {
    max-height: 80px;
    max-width:50px;
}

.plb_diagramImage {
    width: inherit;
}

.plb_diagramHeading {
    font-size: 2.125rem;
}

.plb_diagramLine_desktop {
    background-color: #FFC820;
    width:20%;
    height:5px;
}

.plb_diagramLine_mobile {
    background-color: #FFC820;
    width:5px;
    height:40px;
}

.plb_diagramCenterBox {
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
}

.plb_quoteImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.plb_quoteHeading {
    text-align: left !important;
    color: var(--primary-dark-gray) !important;
    font: normal normal 800 1.375rem/2.1875rem Open Sans !important;
    letter-spacing: 0.1525rem !important;
    margin-top: 2rem !important;
}

.underline {
    text-decoration: underline;
}

.plb_quoteBody {
    font: normal normal normal 1.1875rem/2rem Open Sans !important;
    letter-spacing: 0rem;
    color: #202529 !important;
    max-width: 250px;
}

.plb_quoteContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
}
.plb_quoteMark {
    font: normal normal bold 7.5rem/1.875rem Arial !important;
    letter-spacing: 0rem !important;
    color: #DEDEDE !important;
    opacity: 0.4;
}
.plb_quoteStart {
    text-align: left !important;
    line-height: 100% !important;
    margin-bottom: -5rem !important;
    margin-left: -20.625rem !important;
}
.plb_quoteEnd {
    text-align: right !important;
    line-height: 100% !important;
    margin-top: -6.25rem !important;
    margin-right: -15.625rem !important;
}

.plb_accreditedText {
    font-size: 1.375rem !important;
    text-align: center !important;
    height: 100%;
}

.plb_accreditedBox {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #BCBCBC;
    border-radius: 1.5625rem;
    opacity: 1;
    width:100%;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    padding-left:4.0625rem;
    padding-right: 4.0625rem;
    box-sizing:border-box;
}

.plb_accreditedBoxHeading {
    font-size:1.375rem !important;
    font-weight: 600 !important;
}

.plb_accreditedBoxBody {
    font-size: 1.25rem !important;
    line-height: 2rem !important;
}

.plb_accreditedBoxBodyFlex {
    font-size: 1.25rem !important;
    line-height: 2rem !important;
    display: flex !important;
}

.plb_posProviderInfoContainer {
    display: flex;
    align-items: center;

}

.plb_accreditedBoxProviderName {
    margin-right: 0.625rem !important;
}

.plb_accreditedBoxProviderName_selectable {
    color: var(--primary-purple) !important;
    text-decoration: underline;
    margin-right: 0.625rem !important;
}

.plb_accreditedBoxProviderName_selectable:hover {
    text-shadow:0rem 0rem 0.0313rem var(--primary-purple);
}

.plb_infoIcon {
    margin-bottom: -0.125rem;
}

.plb_posProviderDialogContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.plb_posProviderDialogHeading {
    text-align: left !important;
}

.plb_posProviderDialogBody {
    text-align: left !important;
    overflow-wrap: anywhere;
    word-break:break-word ;
}
.plb_overflow_wrapper{
    overflow-wrap:break-word;
    word-break:break-word ;
}
