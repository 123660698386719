.signature_div_border {
    border: 0.0625rem solid rgba(0, 0, 0, 0.12);
    margin-top: 2.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 0.75rem;
}

.signature_dialog_title_close_text {
    text-align: left;
    font: normal normal bold  1.4375rem/1.0625rem Open Sans;
    letter-spacing: 0.0475rem;
    color: #000000DE;
    opacity: 1;
}

.signature_dialog_sign_above_text {
    text-align: center;
    /*font: normal normal bold  1.125rem/1.625rem Open Sans !Important;*/
    /*letter-spacing: -0.0281rem;*/
    color: #000000;
    /*opacity: 0.37;*/
}

.signature_sign_above_box {
    display: flex;
    align-content: center;
    justify-content: center;
}

.signature_dialog_border_top {
    border-top: 0.0625rem solid #707070;
    width: 100%;
    opacity: 0.4;
}

.signature_dialog_tandc_box {
    background: #F0F0F0 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.signature_dialog_text {
    text-align: left !important;
    white-space: pre-wrap;
    font: normal normal 600 0.9375rem/1.625rem Open Sans !important;
    letter-spacing: -0.0238rem !important;
    opacity: 0.74;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 0.75rem;
}

.signature_dialog_bottom_border {
    border-bottom: 0.0625rem solid #707070;
    width: 100%;
    opacity: 0.4;
}

.signature_dialog_spinner {
    margin-top : 1.875rem;
    margin-bottom : 1.875rem;
    text-align: center;
}

.signature_dialog_error_alert {
    border-top: 0.0625rem solid #b8b8b8;
    border-bottom: 0.0625rem solid #b8b8b8;
    width: 100%;
    margin-top: 1.875rem;
}

.signature_dialog_alert_title_text {
    text-align: left !important;
    width: 100%;
    font: bold bold 600 0.9375rem/1.625rem Open Sans !important;
    letter-spacing: -0.0238rem !important;
}

.span_css {
    overflow-wrap: anywhere;
}

.signature_dialog_alert_text {
    width: 100%;
    text-align: left !important;
    font: normal normal 600 0.9375rem/1.625rem Open Sans !important;
    letter-spacing: -0.0238rem !important;
}

.signature_dialog_w_spacer {
    padding-top: 3rem;
}

.signature_dialog_wo_spacer {
    padding-top: 0.3125rem;
}

.signature_dialog_centerButton {
    display: flex;
    justify-content: center;
}

.signature_dialog_privatePolicyHighlight {
    color: var(--primary-purple);
    text-decoration: underline;
    cursor: pointer;
}

.signature_dialog_actions {
    flex-direction: column;
}

.signature_dailog_endtext { 
    text-align: center;
    letter-spacing: 0rem;
    color: #000000;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 0.875rem !important;
}

@media screen and (min-width : 57.1875rem) {
    .signature_dialog_title_text {
        color: var(--primary-black) !important;
        text-align: left !important;
        padding-left: "1.5625rem";
    }
}

@media screen and (max-width : 57.1875rem) {
    .signature_dialog_clear_button {
        text-align: center;
        padding-left: 0px !important;
    }

    .signature_dialog_title_text {
        color: var(--primary-black) !important;
        line-height: 1.875rem !important;
    }

    .signature_div_border {
        border: 0.0625rem solid rgba(0, 0, 0, 0.12);
        margin-top: 0rem;
        margin-left: 0rem;
        margin-right: 0rem;
        margin-bottom: 0.625rem;
    }
  }

