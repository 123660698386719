@media (max-width: 1023px) {
    
    .stepperBackButton {
        display:none !important;
    }
}

.stepperBackButton_container {
    display: flex;
    align-items: center;
    height: 100%;
}

.stepperBackButton {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
