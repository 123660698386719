@media (min-width: 1024px){
    .yourDetails_background {
        padding-bottom: 6.875rem;
        padding-top: 3.3125rem;
        flex: 1 1;
    }

    .yourDetails_formBox {
        padding-left: 3.4375rem;
        padding-right: 3.4375rem;
        padding-bottom: 3.75rem;
        padding-top: 3.4375rem;
        width: 870px;
        height: fit-content;
    }
}

.yourDetails_heading5 {
    max-width: 605px;
    white-space: pre-wrap;
    height: 100%;
}

.yourDetails_textField {
    margin-bottom: 1.25rem !important;
}

.yourDetails_errorText {
    margin-bottom: 1.25rem !important;
    margin-left: 0.875rem !important;
    color: var(--error-red) !important;
    opacity: 1 !important;
}

.yourDetails_alert {
    margin-top: -0.9375rem;
    /* align-items: center; */
    margin-bottom: 0.9375rem;
}

.yourDetails_alertDesc {
    font-size: 0.8rem !important;
}

.yourDetails_heading3 {
    max-width: 605px !important;
}

.yourDetails_checkBoxContainer {
    border: 0.0625rem solid #F2F2F2;
    border-radius: 0.3125rem;
    opacity: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.yourDetails_checkBoxText {
    color: #505050 !important;
    text-align: left !important;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
    margin-right: 7px !important;
}

.yourDetails_nextButtonContainer {
    display:flex;
    justify-content: right;
}

.yourDetails_privatePolicy {
    text-align: left !important;
    margin-top: 0.625rem !important;
    margin-bottom: 1.25rem !important;
}

.yourDetails_privatePolicyHighlight {
    color: var(--primary-purple);
    text-decoration: underline;
    cursor: pointer;
}


.flexAddress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}

.yourDetails_splitRowWidth {
    width: 49%;
}

.yourDetails_heading1 {
    height: 100%;
}

.yourDetails_heading3 {
    height: 100%;
}
