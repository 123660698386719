body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  --primary-white: #FFFFFF;
  --primary-black: #000000;
  --primary-purple: #5F277E;
  --secondary-purple: #8342A7;
  --background-purple: #5B3084;
  --primary-purple-text: #500A78;
  --dss-light-purple: #FAF7FC;
  --primary-yellow: #FEE98E;
  --secondary-yellow: #E6B000;
  --primary-dark-gray: #222222;
  --dss-dark-green: #004052;
  --dss-dark-green-text:#005A70;
  --dss-light-green: #65C3B3;
  --dss-light-teal: #a8ddd4;
  --disabled-button-gray: #A7A7A7;
  --error-red: #B30700;
}
