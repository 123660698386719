
@media (min-width: 1440px) {
    .DialogTemplate_CloseIcon {
        padding: 1rem !important;
    }
}

@media (min-height: 520px) {
    .DialogTemplate_ContentContainer {
        max-height: 66vh !important;
    }
}

.DialogTemplate_Container {
    display: flex;
}

/* -webkit-scollbar: These css changes should surpress default Iphone adjustments to the web page 
which hides the scroll bar from users by default. These settings should force the scroll bar to appear. */
.DialogTemplate_Container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.DialogTemplate_Container::-webkit-scrollbar:vertical {
    width: 11px;
}

.DialogTemplate_Containerame::-webkit-scrollbar:horizontal {
    height: 11px;
}

.DialogTemplate_Container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
}

.DialogTemplate_Container::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
} 

.DialogTemplate_ButtonText {
    text-align: center;
    font: normal normal bold 1rem/1.25rem Open Sans !important;
    letter-spacing: 0.0181rem;
    opacity: 1;
    text-transform: capitalize;
    margin: 0rem !important;
}

.DialogTemplate_ButtonContainer {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    padding-left: 3rem !important;
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
}

.DialogTemplate_Border {
    border-color: #707070;
    opacity: 0.29;
    border-top: solid;
    border-top-width: 0.0625rem;
}

.DialogTemplate_ContentContainer {
    padding: 0px !important;
    display: flex;
}

.DialogTemplate_TextContainer {
    padding: 2.25rem;
    height: max-content;
}

.DialogTemplate_CloseContainer {
    margin-left: auto;
    position: sticky;
    top: 0;
}

.dialog_title {
    text-align: left !important;
    font: normal normal bold 1.5rem/1.5625rem Open Sans !important;
    letter-spacing: 0.0112rem !important;
    color: var(--primary-purple) !important;
}

.dialog_title_bold {
    text-align: left !important;
    font: normal normal bold  1.5rem/1.875rem Open Sans !important;
    letter-spacing: 0.0112rem !important;
    color: var(--primary-purple) !important;
}

.dialog_text_container {
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
}

.dialog_text {
    text-align: left !important;
    font: normal normal normal 1rem/1.875rem Open Sans !important;
    letter-spacing: 0.0075rem !important;
    color: var(--primary-black);
    white-space: pre-wrap;
    width: 100%;
}

.dialog_text_point {
    text-align: left !important;
    font: normal normal normal 1rem/1.875rem Open Sans !important;
    letter-spacing: 0.0075rem !important;
    color: var(--primary-black);
    white-space: pre-wrap;
    width: fit-content;
}

.dialog_flex {
    display: flex !important;
}