@media (min-width: 1024px) {
    .agreement_display_dssLogo {
        max-width: 350px;
    }
    .agreement_table {
        padding-right: 10%;
    }

    .agreement_text_rightAlign {
        text-align: end;
        padding-right: 0.5rem;
    }
    .agreement_center_centerAlign {
        text-align: center;
    }
}

@media (max-width: 1023px) {
    .agreement_dialogueBoxPadding {
        padding-left:5%;
        padding-right:5%;
    }

    .agreement_display_dssLogo {
        max-width: 200px !important;
    }
    .agreement_display_divWidth {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.agreement_table tr {
    height:60px;
}

.agreement_cell {
    margin:1.875rem;
}

.agreement_paperHeader {
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.agreement_body {
    text-align: left !important;
}

.agreement_paperFooter {
    margin-bottom: 3.4375rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.agreement_display_dssLogo {
    display: block;
    margin-top: 1.5rem;
    margin-right: auto;    
    max-width: 350px;
}

.agreement_display_divWidth {
    width : auto;
}

.agreement_display_heading {
    text-align: center;
    font-family: Helvetica;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    letter-spacing: 0.0313rem;
    color: var(--primary-black);
    height: 100%;
}

.agreement_display_body {
    font-family: Helvetica;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    letter-spacing: 0.0313rem;
    color: var(--primary-black);
    padding : 0rem;
    height: 100%;
}

.agreement_display_ul {
    display: block;
    margin-block-start: 0.0625rem;
    margin-block-end: 0.0625rem;
    margin-inline-start: 1rem;
    margin-inline-end: 0;
    padding-inline-start: 2.5rem;
    height: 100%;
}

.agreement_display_li {
    display: list-item;
    text-align: -webkit-match-parent;
    padding-bottom: 0.25rem;
    height: 100%;
}

.agreement_display_official {
    text-align: center;
    font-family: Helvetica;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    letter-spacing: 0.0313rem;
    color: var(--primary-black); 
}

.agreement_display_no_wrap {
    white-space: nowrap !important;
}
