
.unsupported_background {
    background: url("../../Assets/backgrounds/HomePage_TealBanner_1500x.png");
    background-color: #65C3B3;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.unsupported_panel {
    background-color: #ffffff;
    border-radius: 1.5rem;
    max-width: 60%;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 1rem;
    padding-bottom: 1.5rem;
}

.unsupported_title {
    text-align: center;
    letter-spacing: 0.05rem;
    color: #5F277E;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 1.75rem;
}

.unsupported_text {
    text-align: center;
    letter-spacing: 0.05rem;
    font-family: Open Sans;
    font-size: 1.25rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.75rem;
}

.unsupported_subheader {
    text-align: center;
    letter-spacing: 0.05rem;
    font-family: Open Sans;
    font-size: 1.25rem;
    font-weight: 600;
}

.unsupported_recommends {
    text-align: center;
    letter-spacing: 0.05rem;
    font-family: Open Sans;
    font-size: 1.25rem;
    color: #5F277E;
    text-decoration: underline;
    cursor: pointer;
}