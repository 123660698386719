@media (min-width: 899px) {
    .products_background {
        padding-top: 2.8125rem;
        padding-bottom: 5rem;
    }

    .products_h1 {
        margin-bottom: 2.875rem !important;
    }
}

@media (max-width: 899px) {
    .products_background {
        padding-top: 2.5rem;
        padding-bottom: 3.75rem;
    }

    .products_productBox {
        position: relative;
        bottom: -1.875rem;
    }

    .products_h1 {
        margin-bottom: 1rem !important;
    }
}

.products_h1 {
    height: 100%;
}

.products_background {
    display:flex;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
}


.products_box {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border: 0.0625rem solid #BEBEBE;
    border-radius: 0.9375rem;
    opacity: 1;
    padding-left:2.5rem;
    padding-right:2.5rem;
    padding-top:1.5625rem;
    padding-bottom:1.5625rem;
    height:100%;
    box-sizing: border-box;
}

.products_boxHeading {
    text-align: center;
    font: normal normal bold 1.375rem/1.625rem 'Open Sans' !important;
    letter-spacing: 0rem;
    color: #222222;
    opacity: 1;
    margin-bottom: 0.625rem !important;
}

.products_productBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.products_descriptionBox {
    display: flex;
    align-items: center;
}

.products_image {
    max-width: 110px;
    max-height: 85px;
}
