
@media (min-width: 1024px){
    .footer_gutter {
        padding-left: 8.875rem !important;
        padding-right: 8.875rem !important;
    }
}

@media (max-width: 1024px){
    .footer_container {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

.footer {
    background: #004052 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: auto;
}

.footer_container {
    padding-top: 4.5rem;
    display: flex;
    align-items: center;
}

.footer_site_title {
    text-align: left !important;
    font: normal normal bold  1.75rem/2.8125rem Open Sans !important;
    letter-spacing: 0.0525rem;
    color: var(--primary-white) !important;
    opacity: 1;
    word-break: break-word;
    width: 90%;
}

.footer_link_title {
    text-align: left !important;
    letter-spacing: 0rem !important;
    color: var(--primary-white) !important;
    opacity: 1;
    font-weight: 600 !important;
    margin-bottom: 1.5625rem !important;
}

.footer_link_text {
    text-align: left !important;
    letter-spacing: 0rem;
    color: var(--primary-white) !important;
    opacity: 1;
    margin-bottom: 1.25rem !important;
    cursor: pointer;
    width: 95%;
}

.footer_text {
    text-align: left !important;
    padding-left: 2.5rem;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    border-left: solid;
    border-left-width: thin;
    font: normal normal normal 1.0625rem/1.6875rem Open Sans !important;
    letter-spacing: 0.0225rem;
    color: var(--primary-white) !important;
    opacity: 1;
    width: 80%;
}

.footer_copyright {
    text-align: center;
    letter-spacing: 0rem;
    color: var(--primary-white) !important;
    opacity: 1;
    font: normal normal normal 1rem/1.75rem Open Sans !important;
    padding-top: 2rem;
    padding-bottom: 4.5rem;
}

.footer_optionMargin {
    margin-top: 1.25rem !important;
}