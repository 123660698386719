@media (min-width: 64rem){

    .mpaSubmitted_background {
        padding-bottom: 8.59rem;
        padding-top: 8.5rem;
    }

    .mpaSubmitted_dialogueBox {
        padding-top: 3.44rem;
        padding-bottom: 3.44rem;
        width: 54.5rem;
        border-radius: 0.5rem;
    }
}

.mpaSubmitted_background {
    flex: 1 1;
}

.mpaSubmitted_dialogueBox {
    height: fit-content;
}

.body {    
    max-width: 45rem;
}

.body-margin-bottom {
    max-width: 44.0625rem;
}

.mpaSubmitted_title {
    overflow-wrap: anywhere;
    width: 90%;
}

.mpaThankYou {
    overflow-wrap: anywhere;
    margin-left: 5% !important;
    margin-right: 5% !important;
}