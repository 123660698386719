
@media (max-width: 1023px){
    .compad_gutter {
        padding-left: 5%;
        padding-right: 5%;
    }
    .compatibility_stepper {
        display: none !important;
    }
    .compatibility_stepper_button {
        display: none !important;
    }
    .compatibility_check_button {
        width: 100% !important;
        margin-bottom: 2.5rem !important;
    }
    .compatibility_stepper_container {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }
}

@media (min-width: 1023px) {
    .compad_gutter {
        padding-left: 8.875rem;
        padding-right: 8.875rem;
    }
    .compatibility_mobile_stepper {
        display: none !important;
    }
}

.compad_body {
    max-width: 86.875rem;
    margin-left: auto;
    margin-right: auto;
}

.compatibility_result_container {
    margin-top: 3.75rem !important;
    margin-bottom: 7.5rem !important;
    padding: 1.25rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #707070;
    border-radius: 1.25rem;
    opacity: 1;
    text-align: center;
}

.compatibility_result_text {
    text-align: center;
    font: normal normal bold 1.625rem/2rem Open Sans !important;
    letter-spacing: -0.0406rem !important;
    white-space: pre-wrap;
    color: #0B0B0B !important;
    opacity: 1;
    max-width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 0.625rem !important;
}

.compatibility_stepper_container {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.25rem 0.5rem #60617029;
    opacity: 1;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
}

.compatibility_stepper_button_arrow {
    margin-right: 0.75rem;
}

.compatibility_stepper_flex {
    display: flex;
}

.compatibility_stepper {
    flex: 1;
}

.compatibility_general_container {
    padding-top: 3.25rem;
    margin-bottom: 7.5rem;
}

.compatibility_general_title_container {
    height: 100%;
}

.compatibility_general_title {
    text-align: left !important;
    font: normal normal bold 2.25rem/2rem Open Sans !important;
    letter-spacing: -0.0563rem !important;
    color: var(--dss-dark-green-text) !important;
    opacity: 1;
    height: 100%;
}

.compatibility_general_text {
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    text-align: left !important;
    letter-spacing: -0.0281rem !important;
    color: #000000;
    opacity: 1;
    padding-top: 1.25rem;
    height: 100%;
}

.compatibility_general_label {
    text-align: left !important;
    font: normal normal bold 1rem/1.5rem Arial !important;
    letter-spacing: 0.0181rem !important;
    color: #000000DE !important;
    opacity: 1;
    padding-bottom: 0.75rem;
    white-space: pre-wrap;
    height: 100%;
}

.compatibility_question {
    padding-top: 2rem;
}

.compatibility_checkboxs {
    margin-top: 0rem !important;
}

.compatibility_checkboxs_content {
    padding-top: 0rem !important;
}

.compatibility_checkbox_label {
    text-align: left !important;
    font: normal normal normal 1rem/1.125rem Arial !important;
    letter-spacing: 0rem !important;
    color: #1A1A1A !important;
    opacity: 1;
    padding-left: 0.75rem;
}

.compatibility_general_checkbox_container {
    padding: 0.5625rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    -webkit-tap-highlight-color: transparent;
    border: 0.125rem solid #707070;
    border-radius: 0.1875rem;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.compatbility_checkbox_not_selected {
    border-color:#d1d1d1;
}

.compatibility_checkbox_selected {
    border: 0.125rem solid var(--primary-purple);
}

.compatibility_check_error_text {
    text-align: left !important;
    font: normal normal normal 0.75rem/1rem Open Sans !important;
    letter-spacing: 0.025rem !important;
    color: var(--error-red) !important;
    padding-top: 0.5rem;
    opacity: 1;
}

.compatibility_warning_container {
    margin-top: 2rem;
}

.compatibility_warning_text {
    text-align: left !important;
    font: normal normal 600 0.75rem/1rem Open Sans !important;
    letter-spacing: 0.025rem !important;
    opacity: 1;
}

.compatibility_checkbox_info_click {
    opacity: 1;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
}

.compatibility_checkbox_info_text {
    text-decoration: underline;
    font: normal normal normal 1rem/1.5rem Arial;
    letter-spacing: 0.0181rem !important;
    color: var(--primary-purple-text) !important;
}

.compatibility_checkbox_info {
    padding-bottom: 0.625rem;
    display: flex;
    justify-content: flex-end;
}

.compatibility_checkbox_info_icon {
    opacity: 1;
    border-radius: 100%;
    color: var(--primary-purple-text);
    height: 1.5rem !important;
    margin-right: 0.5rem;
    width: 24px !important;
}

.compatibility_check_button {
    margin-top: 3rem !important;
}

.compatibility_check_button_text {
    text-transform: capitalize;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin: 0rem !important
}

.compatibility_image_container {
    display: flex;
}

.compatibility_check_image {
    width: 100%;
    align-self: center;
    margin-bottom: 15%;
    max-width: 100%;
    max-height: 100%;
}

.compatibility_result_button {
    margin-top: 1.5rem !important;
    margin-bottom: 3rem !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.compatibility_result_button_text {
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0rem !important;
    white-space: pre-wrap;
    line-height: 1.24rem;
}

.compatibility_result_text_title {
    font: normal normal bold 1.625rem/2rem Open Sans !important;
    letter-spacing: -0.0406rem !important;
    color: var(--dss-dark-green-text) !important;
    opacity: 1;
    white-space: pre-wrap;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.125rem;
    padding-bottom: 2rem;
}

.compatibility_result_text_subtitle {
    font: normal normal 600 1.25rem/1.625rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: #222222 !important;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.compatibility_result_text_bold {
    font: normal normal bold 1.625rem/2rem Open Sans !important;
    letter-spacing: -0.0406rem !important;
    color: #0B0B0B !important;
    opacity: 1;
    max-width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 2rem !important;
}

.compatibility_result_button_container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.compatibility_result_signup_button {
    margin-top: 1.5rem !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.compatibility_result_contact_button {
    border-radius: 3.125rem !important;
    margin-top: 1.5rem !important;
    margin-bottom: 3rem !important;
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}

.compatibility_icon_margin {
    margin-top: 1rem;
}

.compatibility_icon_scaledown {
    margin-top: 16px;
    max-width: 16rem;
    max-height: 16rem;
}

.css-egtxoq-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #178639 !important;
}

.compatibility_mobile_stepper {
    width: 100%;
}

.compatibility_mobile_stepper_text_container {
    margin-left: auto;
    text-align: right;
}

.compatibility_mobile_progress_container {
    position: relative;
    display: inline-flex;
}

.compatibility_mobile_progress {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.compatibility_mobile_progress_header {
    font: normal normal 600 1.125rem/2rem Roboto !important;
}

.compatibility_mobile_progress_subheader {
    font: normal normal 400 0.75rem/1.25rem Roboto !important;
}

.compatibility_mobile_progress_circle {
    position: absolute;
    color: #e2e2e2 !important;
}

.compatibility_hint {
    text-align: left !important;
    font: normal normal normal 0.75rem/1.25rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: #363636 !important;
    opacity: 0.72;
    margin-top: 1rem !important;
    height: fit-content;
}

.compatibility_check_radio {
    padding: 0px !important;
}
