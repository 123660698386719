
@media (max-width: 1023px){
    .terms_background {
        padding-top: 5vh !important;
        padding-bottom: 10vh !important;
    }
    .terms_title_text {
        width: 100% !important;
    }
    .terms_panel {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media (min-width: 1023px){
    .terms_panel {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
    }
}

.terms_background {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 6vh;
    padding-bottom: 20vh;
    background: url("../../../Assets/backgrounds/Teal-banner-darker.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.terms_panel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.625rem 2.1875rem #00000008;
    opacity: 1;
    margin-bottom: 2.5rem;
    max-width: 55rem;
}

.terms_title_title {
    font: normal normal bold  2rem/2.375rem Open Sans !important;
    letter-spacing: 0.015rem !important;
    color: var(--dss-dark-green-text) !important;
    opacity: 1;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    height: 100%;
}

.terms_title_text {
    font: normal normal bold  1.125rem/1.625rem Open Sans !important;
    letter-spacing: -0.028125rem !important;
    white-space: pre-wrap;
    opacity: 1;
    padding-bottom: 2rem;
    width: 70%;
    align-self: center;
    height: 100%;
}

.terms_flexcenter {
    display: flex;
    flex-direction: column;
}

.terms_container {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.125rem 0.125rem #60617029;
    border: 0.0313rem solid #5F277E;
    border-radius: 0.1875rem;
    opacity: 1;
    max-height: 280px;
    overflow: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.terms_container::-webkit-scrollbar {
    width:10px;
}

.terms_container::-webkit-scrollbar-track {
    background-color: #F8F8F8;
}

.terms_container::-webkit-scrollbar-thumb {
    background: #00000073;
    border-radius: 0.625rem;
    border: 0.1563rem solid transparent;
    background-clip: content-box;
}


.terms_text {
    text-align: left !important;
    font: normal normal normal 1rem/1.625rem Open Sans !important;
    letter-spacing: 0.0125rem !important;
    color: #222222 !important;
    white-space: pre-wrap;
}

.terms_text_inline_point {
    text-align: left !important;
    font: normal normal normal 1rem/1.625rem Open Sans !important;
    letter-spacing: 0.0125rem !important;
    color: #222222 !important;
    white-space: pre-wrap;
    width: fit-content;
}

.terms_text_inline {
    text-align: left !important;
    font: normal normal normal 1rem/1.625rem Open Sans !important;
    letter-spacing: 0.0125rem !important;
    color: #222222 !important;
    white-space: pre-wrap;
    width: 100%;
}

.terms_flex {
    display: flex !important;
}

.terms_response_grid {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem;
}

.terms_response_radio_button {
    margin-top: 12px !important;
    width: fit-content;
    align-self: center;
}

.terms_response_divide {
    border-top: 0.0625rem solid #707070;
    opacity: 0.27;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.terms_response_container_selected {
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.5rem 0.75rem #60617029;
    border: 0.125rem solid #5F277E;
    border-radius: 1.5rem;
    opacity: 1;
    cursor: pointer;
    height: 100%;
}

.terms_response_container {
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.5rem 0.75rem #60617029;
    border: 0.0625rem solid #C8C8C8;
    padding: 0.0625rem;
    border-radius: 1.5rem;
    opacity: 1;
    cursor: pointer;
    height: 100%;
}

.terms_response_text_selected {
    font: normal normal bold  1.125rem/1.625rem Open Sans !important;
    letter-spacing: 0.01rem !important;
    color: #000000;
    white-space: pre-line;
    padding-top: 2rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.terms_response_text {
    font: normal normal 600 1.125rem/1.625rem Open Sans !important;
    color: #484848 !important;
    white-space: pre-line;
    padding-top: 2rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.terms_button {
    background-repeat: no-repeat;
    background-clip: padding-box;
    border-radius: 1.5625rem !important;
    opacity: 1;
    margin-top: 1rem !important;
    margin-bottom: 2.25rem !important;
    width: 100%;
}

.terms_button_text {
    text-transform: capitalize;
    padding-left: 3rem;
    padding-right: 3rem;
    margin: 0rem !important;

}