@media (min-width: 1023px){
    .contactus_panel {
        padding-left: 4.375rem;
        padding-right: 4.375rem;
    }
}

@media (max-width: 1023px){
    .contactus_background {
        padding-top: 5vh !important;
        padding-bottom: 10vh !important;
    }

    .contactus_panel {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.contactus_background {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10vh;
    padding-bottom: 20vh;
    background: url("../../Assets/backgrounds/Teal-banner-darker.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.contactus_panel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.625rem 2.1875rem #00000008;
    border-radius: 0.5rem;
    opacity: 1;
    margin-bottom: 2.5rem;
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
    padding-left: 5%;
    padding-right: 5%;
    max-width: 68.75rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.contactus_title_text {
    text-align: center;
    font: normal normal bold 2.25rem/3rem  Open Sans !important;
    letter-spacing: 0.0125rem !important;
    color: #000000;
    opacity: 1;
    padding-bottom: 1.25rem;
    padding-left: 5%;
    padding-right: 5%;
}

.contactus_text {
    text-align: center;
    letter-spacing: 0rem !important;
    font: normal normal normal 1.25rem/2.25rem Open Sans !important;
    opacity: 1;
}

.contactus_error_text {
    text-align: left !important;
    font: normal normal normal 0.75rem/0.875rem Open Sans !important;
    letter-spacing: 0.025rem !important;
    color: var(--error-red)  !important;
    padding-top: 0.5rem;
    opacity: 1;
    align-self: flex-start;
    margin-left: 0.625rem !important;
}

.contactus_text_highlight {
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0rem;
    color: #004052  !important;
    cursor: pointer;
}

.contactus_text_instructions {
    text-align: center;
    letter-spacing: 0rem !important;
    font: normal normal normal 1.25rem/2.25rem Open Sans !important;
    opacity: 1;
    margin-bottom: 3rem !important;
    max-width: 45rem;
}

.contactus_box_container {
    padding-top: 2.5rem;
    padding-bottom: 3.75rem;
}

.contactus_box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.375rem 0.625rem #00000008;
    border: 0.0625rem solid #E5EAF4;
    border-radius: 0.5rem;
    opacity: 1;
    height: 100%;
}

.contactus_box_padding {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
}

.contactus_icon {
    width: 40px;
    height: 40px;
    align-self: center;
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
}

.contactus_box_header {
    font: normal normal 600 1.1875rem/1.1875rem Open Sans !important;
    text-align: center;
    letter-spacing: 0.0119rem;
    color: #183B56 !important;
    opacity: 1;
    overflow-wrap: break-word;
}

.contactus_selectable {
    cursor: pointer;
}

.contactus_box_text {
    font: normal normal normal 0.9375rem/1.5rem Open Sans !important;
    text-align: center;
    letter-spacing: 0rem;
    color: #5A7184 !important;
    opacity: 1;
    margin-top: 1rem !important;
    margin-bottom: 0.625rem !important;
}

.contactus_input_box {
    width: 100%;
    margin-top: 1.25rem !important;
}

.css-mc3cz7-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0rem !important;
}

.css-bebcks-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 0rem !important;
}

.contactus_conditions {
    font: normal normal 500 1rem/1.75rem Open Sans !important;
    text-align: left !important;
    letter-spacing: 0rem;
    margin-top: 1.5rem !important;
    margin-bottom: 1rem !important;
    align-self: flex-start;
}

.contactus_conditionsHighlight {
    color: var(--primary-purple);
    text-decoration: underline;
    cursor: pointer;
}

.contactus_hint {
    text-align: left !important;
    font: normal normal normal 0.75rem/1.25rem Open Sans !important;
    letter-spacing: 0rem !important;
    color: #363636 !important;
    opacity: 0.72;
    align-self: flex-start;
}

.contactus_button {
    background-repeat: no-repeat;
    background-clip: padding-box;
    border-radius: 1.5625rem !important;
    opacity: 1;
    margin-top: 2rem !important;
    align-self: flex-start;
}

.contactus_button_text {
    letter-spacing: 0rem !important;
    opacity: 1;
    padding-left: 3rem;
    padding-right: 3rem;
    margin: 0rem !important;
}

.thankyou_background {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20vh;
    padding-bottom: 20vh;
    background: url("../../Assets/backgrounds/HomePage_TealBanner_1500x.png");
    background-size: cover;
    background-repeat: no-repeat;
    flex: 1;
}

.thankyou_panel {
    display: flex;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.625rem 2.1875rem #00000008;
    border-radius: 0.5rem;
    opacity: 1;
    margin-bottom: 2.5rem;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
}

.thankyou_title {
    text-align: center;
    font: normal normal bold 3.25rem/4.25rem Open Sans !important;
    letter-spacing: 0.0125rem !important;
    opacity: 1;
    padding-top: 3.25rem;
    overflow-wrap: anywhere;
    margin-left: 5% !important;
    margin-right: 5% !important;
}

.thankyou_text {
    text-align: center;
    font: normal normal normal 1.25rem/2.25rem Open Sans !important;
    letter-spacing: 0rem !important;
    opacity: 1;
    padding-top: 1.25rem;
    margin-left: 10% !important;
    margin-right: 10% !important;
}

.thankyou_button {
    border-radius: 1.5625rem !important;
    opacity: 1;
    margin-top: 2.625rem !important;
    margin-bottom: 4.5rem !important;
    align-self: center;
    width: fit-content;
}

.contactus_dialog {
    flex-grow: 1;
}

.contactus_dialog_message {
    margin-bottom: 2rem !important;
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

.contactus_dialog_button {
    margin-bottom: 2rem !important;
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}