@media (min-width: 1024px) {
    .mpaStepper_header {
        padding-top:0.9375rem;
        padding-bottom:0.9375rem;
        padding-left: 2rem;
        padding-right: 2rem;
        min-height: 65px;
    }

    .mpaStepper_mobile {
        display: none !important;
    }
}

@media (max-width: 1023px) {
    .mpaStepper_mobile {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
    }

    .mpaStepper {
        display: none !important;
    }
}

@media(min-width: 1440px) {
    .mpaStepper_header {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}

.mpaStepper {
    flex: 1;
}

.mpaStepper_stepContainer {
    display:flex;
}

.mpaStepper_textContainer_mobile {
    margin-left: auto;
    text-align: right;
}

.mpaStepper_progressContainer_mobile {
    position: relative;
    display: inline-flex;
}

.mpaStepper_progress_mobile {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mpaStepper_activeStepText_mobile {
    font: normal normal 600 1.125rem/1.5rem Roboto !important;
    color: var(--primary-purple) !important;
    text-align: right !important;
}

.mpaStepper_nextStepText_mobile {
    font: normal normal 400 0.75rem/1.25rem Roboto !important;
    color: var(--primary-purple) !important;
    text-align: right !important;
}

.mpaStepper_progressCircle_mobile {
    position: absolute;
    color: #e2e2e2 !important;
}

.mpa_step_contents {
    display: contents;
}

.mpaStepper_wrapLabel {
    white-space: pre;
    width: min-content;
}