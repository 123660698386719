@media (min-width: 63.9375rem) {
    .factsContact_background {
        padding-top: 2.8125rem;
        padding-bottom: 3.4375rem;
    }
}

@media (max-width: 63.9375rem) {
    .factsContact_background {
        padding-top: 2.5rem;
        padding-bottom: 3.75rem;
    }
}

.factsContact_background {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.factsContact_h1 {
    color: var(--primary-black) !important;
}

.factsContact_gridContainer {
    max-width: 65rem;
}

