@media (min-width: 1023px) {
    .esign_background {
        padding-top: 3.125rem;
        padding-bottom: 3.125rem;
    }
    .esign_dialogueBox {
        padding-top: 2.1875rem;
        padding-bottom:2.1875rem;
        max-width: 1315px !important;
    }

    .esign_dialogueBoxPadding {
        padding-left:2.1875rem;
        padding-right:2.1875rem;
    }

    .esign_centreBoxPadding {
        padding-left:2.1875rem;
        padding-right:2.1875rem;
    }

    .esign_paper {
        margin-bottom: 2.5rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .esign_divider1 {
        margin-bottom: 2.6875rem !important;
    }

    .esign_backButton {
        display:none !important;
    }
    
    .esign_heading1 {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 0.5rem;
        padding-left: 10.3125rem;
        padding-right: 10.3125rem;
    }
}

@media (max-width: 1023px) {
    .esign_dialogueBoxPadding {
        padding-left:5%;
        padding-right:5%;
    }
    
    .esign_heading1 {
        width: 95%;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .esign_centreBoxPadding {
        padding:0rem;
    }

    .esign_paper {
        margin-bottom: 0rem;
    }

    .esign_divider1 {
        margin-bottom: 0rem !important;
    }
}

.esign_heading5 {
    max-width: 945px !important;
    padding-bottom: 2rem;
    height: 100%
}

.dssLogo {
    max-width: 350px;
}

.esign_table tr {
    height:60px;
}

.esign_paperFooter {
    margin-bottom: 3.4375rem;
}

.esign_dialogBoxFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.esign_paper {
    background: var(--primary-white) 0% 0% no-repeat padding-box !important;
    border: 0.0625rem solid #C6C6C6 !important;
    opacity: 1;
    height:fit-content;
}

.esign_paperHeader {
    margin-bottom: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.esign_body {
    text-align: left !important;
}

.esign_paperFooter {
    display: flex;
    align-items: center;
    flex-direction: column;
}
