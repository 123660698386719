@media (min-width: 899px) {
    .goLiveStep_arrow_mobile {
        display: none !important;
    }
}

@media (max-width: 899px) {
    .goLiveStep_headingBox {
        position: relative;
        bottom: -1.25rem;
    }

    .goLiveStep_arrow_desktop {
        display: none !important;
    }
}

.goLiveStep_container {
    width:100%;
}

.goLiveStep_box {
    background: var(--primary-white) 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.1875rem 0.375rem #00000029;
    border: 0.0625rem solid #BEBEBE;
    border-radius: 0.9375rem;
    height:100%;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    display:flex;
    align-items: center;
    box-sizing: border-box;
    
}

.goLiveStep_boxContainerWithArrow {
    display:flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    width: 100%;
    height: 100%;
}

.goLiveStep_boxContentContainer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width:100%;
    height: 100%;
    justify-content: space-between;
}

.goLiveStep_arrow {
    width: 45px !important;
    height: 45px !important;
    z-index: 9;
}

.goLiveStep_arrow_desktop {
    margin-bottom: -2.8125rem;
}

.goLiveStep_arrow_mobile {
    margin-bottom: -4.0625rem;
}

.goLiveStep_heading {
    text-align: left !important;
    font: normal normal bold  1.375rem/1.625rem Open Sans !important;
    color: #222222 !important;
    margin-right: 0.625rem;
}

.goLiveStep_icon {
    height:auto;
    max-height: 75px;
    max-width: 75px;
}

.goLiveStep_body {
    text-align: left !important;
    color: #222222 !important;
}