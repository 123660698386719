
.failure_dialog_message {
    margin-bottom: 2rem !important;
    margin-left: 3rem !important;
    margin-right: 3rem !important;
    white-space: pre-wrap;
}

.failure_dialog_button {
    margin-bottom: 2rem !important;
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}
