
.pageNotFound {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10vh;
    padding-bottom: 30vh;
    background: url("../../Assets/backgrounds/HomePage_TealBanner_1500x.png");
    background-size: cover;
    background-repeat: no-repeat;
    flex: 1;
}

.pageNotFoundText {
    color: var(--primary-black);
    font-size: large !important;
    font-weight: 600 !important;
}